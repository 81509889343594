.list-component {
  .sort-icon {
    fill: white;
    stroke: rgba(228, 236, 255, 1);

    &:hover {
      cursor: pointer;
      fill: rgba(186, 206, 255, 1);
      stroke: #2e62e7;
    }
  }

  .list-item {
    display: flex;
    flex-direction: row;
    min-height: 40px;
    min-height: auto;
    border-radius: 5px;
    justify-content: space-between;
    border: 1px solid #c8c8c8;
    align-items: center;
    padding: 4.5px 12.5px;
    overflow-wrap: normal;
    gap: 20px;
    width: 100%;

    &:hover {
      border: 1px solid #2e62e7;
      cursor: pointer;
      background-color: #eff0f3;
    }
  }
  .lowlight-list-item {
    background-color: #eff0f3;
    color: #9d9d9d;
    &:hover {
      border: 1px solid #c8c8c8;
      cursor: pointer;
      color: #9d9d9d;
      background-color: #eff0f3;
    }
  }

  .striped-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #c8c8c8;
    align-items: center;
    padding: 0px 16px;
    gap: 20px;
    overflow-wrap: normal;
    align-items: flex-start;
    width: 100%;
  }

  .striped-list-item-clickable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #c8c8c8;
    align-items: center;
    padding: 0px 16px;
    gap: 20px;
    overflow-wrap: normal;
    align-items: flex-start;
    width: 100%;
    &:hover {
      cursor: pointer;
      background-color: #e4ecff;
    }
  }

  .striped-gray-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #c8c8c8;
    align-items: flex-start;
    padding: 0px 16px;
    background-color: #f5f5f7;
    gap: 20px;
    overflow-wrap: normal;
    width: 100%;
  }

  .striped-gray-list-item-clickable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #c8c8c8;
    align-items: flex-start;
    padding: 0px 16px;
    background-color: #f5f5f7;
    gap: 20px;
    overflow-wrap: normal;
    width: 100%;
    &:hover {
      cursor: pointer;
      background-color: #e4ecff;
    }
  }

  .extraRenders {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
}
