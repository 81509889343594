// Remove all padding/margin from the adherence columns so we can put an underline below them and have the columns
// Look like they are one continuous.
.starsAdherence-measureColumn {
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  gap: 0 !important;

  .listItem-div {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    gap: 0 !important;
  }
}

.starsAdherence-measureColumn-subRow {
  border-bottom: 1px solid #c8c8c8;
  padding-bottom: 5px;
  padding-top: 5px;

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }

  &:first-of-type {
    padding-top: 2px;
  }
}
